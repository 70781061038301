import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "reducers";
import { PureModal, TableButton } from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import IoMdPerson from "react-icons/lib/io/person";
import { setPatientBatchIds } from "actions/batch";
import BulkUpdateTasks from "../bulk-update-tasks";
import { createTab } from "actions/system-tray";
import IoMdEdit from "react-icons/lib/io/edit";
import { TableSettingsNew } from "hooks/useTableSettings";
import { put } from "utils/api";
import { useFetch } from "hooks";
import { errorMessage } from "actions/message";
import { setFilter, clearFilter } from "hooks/useQuery/actions";
import { exportAllTasks } from "actions/exports";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import * as R from "ramda";

const TasksTableControls = ({
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  queryDispatcher,
  query,
  tableSettings,
  resetTableSettings,
  updateTableSettings
}) => {
  const dispatch = useDispatch();

  const bulkUpdatePatientTasksAPI = useFetch({
    apiFn: payload => put("tasks/bulk-update", payload),
    onError: () => dispatch(errorMessage("Failed to update tasks"))
  });
  const userId = useSelector(selectors.getUserId);
  const showMyTasks = query.filters?.assigned_to == userId;
  return (
    <div className="query-table-controls">
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoMdRefresh />
      </TableButton>
      <TableButton
        title="Export all tasks to excel file"
        onClick={async () => {
          const columnsToExport = Object.keys(tableSettings).filter(key => tableSettings[key].active);
          await dispatch(exportAllTasks(query, columnsToExport));
        }}
      >
        <FaFileExcelO />
      </TableButton>
      <TableButton
        title={`Process Tasks: (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const patientIds = selectedRows.map(x => x.patient_id);
          const patientNames = selectedRows.map(x => x.patient_name);
          dispatch(setPatientBatchIds(patientIds, patientNames));
          dispatch(
            createTab({
              type: "patients",
              key: "patients",
              label: `Patients (${patientIds.length})`,
              meta: { ids: patientIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoMdPerson size={24} />
      </TableButton>
      <PureModal
        renderTrigger={({ openModal }) => (
          <TableButton
            disabled={selectedRows.length === 0}
            onClick={openModal}
            title={`Bulk update Tasks - (${selectedRows.length} selected)`}
          >
            <IoMdEdit />
          </TableButton>
        )}
        renderContent={({ closeModal }) => (
          <BulkUpdateTasks
            tasks={selectedRows}
            clearSelectedRows={clearSelectedRows}
            closeModal={closeModal}
            refreshData={refreshData}
            handleBulkUpdateTasks={async payload => {
              await bulkUpdatePatientTasksAPI.fetchData(payload);
              clearSelectedRows();
              refreshData();
              closeModal();
            }}
          />
        )}
      />
      <div
        style={{
          flex: 1,
          display: "flex",
          paddingRight: 15,
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}
          className="radio-group-container"
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
              color: "white"
            }}
          >
            <span style={{ paddingRight: 5 }} className="radio-group-option">
              My Tasks
            </span>
            <input
              type="radio"
              className="input-field"
              style={{ margin: 0 }}
              checked={showMyTasks}
              onChange={({ target }) => {
                if (target.checked) {
                  queryDispatcher(setFilter("assigned_to", userId));
                }
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
              color: "white"
            }}
          >
            <span style={{ paddingRight: 5 }} className="radio-group-option">
              All Tasks
            </span>
            <input
              type="radio"
              className="input-field"
              style={{ margin: 0 }}
              checked={!showMyTasks}
              onChange={({ target }) => {
                if (target.checked) queryDispatcher(clearFilter("assigned_to"));
              }}
            />
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Tasks</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        <TableSettingsNew
          tableSettings={tableSettings}
          updateTableSettings={updateTableSettings}
          resetTableSettings={resetTableSettings}
        />
      </div>
    </div>
  );
};

export default TasksTableControls;

TasksTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  tableSettings: PropTypes.object.isRequired,
  resetTableSettings: PropTypes.func.isRequired,
  updateTableSettings: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  queryDispatcher: PropTypes.func.isRequired
};
