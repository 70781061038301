import React from "react";
import CompanyTeamSelect from "components/shared/company-team-select";
// import SleepCoachTeamSelect from "components/shared/coach-team-select";
// import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";
import { Link } from "react-router-dom";
// import { taskHeaderOptions } from "utils/enum";
import { RowSelectCell, RowSelectHeaderCell } from "hooks/useRowSelectNew";
import { useTableSettingsNew } from "hooks/useTableSettings";
// import isEqual from "lodash/isEqual";
import { selectors } from "reducers";
// import { Checkbox } from "components/ui";
import { useSelector } from "react-redux";
import { formatDate } from "utils/dates";
import * as R from "ramda";

const getTableSchema = ({
  // sleepCoaches,
  companies,
  userRole
}) => {
  const Table = {
    All: {
      header: "all",
      field: "all",
      Cell: RowSelectHeaderCell,
      active: true,
      Row: row => <RowSelectCell id={row.patient_id} />
    },
    Patient: {
      header: "Patient",
      field: "patient_name",
      active: true,
      Row: row => (
        <td>
          <Link to={`/patients/${row.patient_id}`}>{row.patient_name}</Link>
        </td>
      )
    },
    "Account Number": {
      header: "Account #",
      field: "patient_account",
      active: true,
      Row: row => <td>{row.patient_account}</td>
    },
    Company: {
      header: "Company",
      field: "company",
      filter: "company_id",
      filters: companies,
      filterDownSelect: true,
      SubFilter: CompanyTeamSelect,
      active: true,
      Row: row => <td>{row.company_name}</td>
    },
    /*
    "Date Created": {
      header: "Date Created",
      field: "order_created_at",
      Cell: () => (
        <DateRangeHeaderColumn header="Date Created" field="created_at" />
      ),
      active: true,
      Row: row => <td>{formatDate(row.order_created_at)}</td>
    },
    */
    Progress: {
      header: "Progress",
      field: "task_status",
      active: true,
      filter: "task_status",
      filters: [
        { value: "pending_tasks", text: "In Progress" },
        { value: "completed_tasks", text: "All Tasks Completed" }
      ],
      Row: row => (
        <td>
          {row.completed_tasks}/{row.total_tasks}
        </td>
      )
    },
    "Last Updated": {
      header: "Last Updated",
      field: "last_updated_at",
      // Cell: () => (
      //   <DateRangeHeaderColumn header="Last Updated" field="last_updated_at" />
      // ),
      active: true,
      Row: row => <td>{formatDate(row.last_updated_at)}</td>
    }
  };
  if (["OfficeAdministrator", "CompanyAdministrator"].includes(userRole))
    return R.omit(["Owner"], Table);
  return Table;
};

export const useTaskTable = () => {
  const companies = useSelector(selectors.getFormOptionsCompany);
  // const sleepCoaches = useSelector(getFormOptionsSleepCoachWithUserFirst);
  const userRole = useSelector(selectors.getUserRole);
  const orderStatusOptions = useSelector(selectors.getOrderStatusTextOptions);
  const taskTable = getTableSchema({
    companies,
    // sleepCoaches,
    userRole,
    orderStatusOptions
  });
  const tableSchema = Object.keys(taskTable).map(key => ({
    ...taskTable[key],
    key
  }));
  const defaultSettings = R.mapObjIndexed(R.pick(["active"]), taskTable);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings
  } = useTableSettingsNew("patient-tasks-table-settings", defaultSettings);

  const visibleTableSchema = tableSchema.filter(({ key }) =>
    isColumnVisible(key)
  );
  const tableColumns = visibleTableSchema.map(R.omit(["Row"]));
  const tableRows = visibleTableSchema.map(R.prop("Row"));

  // remove sorting and filters when column is removed
  // React.useEffect(() => {
  //   const newQuery = cleanQuery(
  //     tableColumns.map(({ field }) => field),
  //     query
  //   );
  //   if (!isEqual(newQuery, query)) {
  //     queryDispatcher({ type: "updateQuery", ...newQuery });
  //   }
  // }, [tableColumns, queryDispatcher]);

  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    tableColumns,
    tableRows
  };
};
