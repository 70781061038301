import { transformUiQuery } from "utils/query";
import omit from "lodash/omit";
import { browserHistory } from "browser-history";
import { errorMessage } from "actions/message";
import { put } from "utils/api";

// NOTE: duplicated code, refactored to use thunk for api call instead of saga
export const exportOrdersInvoiceReport = query => async dispatch => {
  try {
    await put(
      "orders/export/invoice",
      omit(transformUiQuery(query), "pagination")
    );
    browserHistory.push("/exports");
  } catch (error) {
    dispatch(errorMessage("Failed to export invoice details."));
  }
};

export const exportOrdersReport = (query, columnsToExport) => async dispatch => {
  try {
    await put("orders/export", {
      ...omit(transformUiQuery(query), "pagination"),
      columnsToExport
    });
    browserHistory.push("/exports");
  } catch (error) {
    dispatch(errorMessage("Failed to export invoice details."));
  }
};
