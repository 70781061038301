import { createContext, useEffect } from "react";
import useTableSettings, { cleanQuery } from "hooks/useTableSettings";
// import { useSelector } from "react-redux";
// import { selectors } from "reducers";
import isEqual from "lodash/isEqual";

export const PatientsTableSettingsContext = createContext();

const usePatientsTableSettings = (query, queryDispatcher) => {
  // const userRole = useSelector(selectors.getUserRole);
  // const isS3Employee = userRole == "SleepCoach" || userRole == "Administrator";
  const columns = [
    {
      header: "all",
      field: "all",
      active: true
    },
    {
      header: "Active",
      field: "active",
      active: true
    },
    {
      header: "Name",
      field: "last_name",
      active: true
    },
    {
      header: "Account #",
      field: "account_number",
      active: true
    },
    {
      header: "Contact Type",
      field: "form_of_contact",
      active: true
    },
    {
      header: "Insurance",
      field: "insurance_company",
      active: true
    },
    {
      header: "Sleep Coach",
      field: "sleep_coach",
      active: true
    },
    {
      header: "Compliance Coach",
      field: "compliance_coach",
      active: true
    },
    {
      header: "Company",
      field: "company",
      active: true
    },
    {
      header: "Insurance Eligibility Date",
      field: "patient_insurance_eligible",
      active: false
    },
    {
      header: "Outreach Date",
      field: "dateEligibleForAutomation",
      active: true
    },
    {
      header: "Outreach Cycle",
      field: "cycle_count",
      active: true
    },
    {
      header: "Offshore Limited",
      field: "disallows_offshore_calls",
      active: true
    },
    {
      header: "Delayed Date",
      field: "delayed_activation_date",
      active: false
    },

    {
      header: "Date Created",
      field: "date_added",
      active: true
    },
    {
      header: "Last Updated",
      field: "last_updated",
      active: true
    },
    {
      header: "Language",
      field: "language",
      active: true
    },
    {
      header: "Ordered",
      field: "has_order_after_added",
      active: true
    },
    {
      header: "New Setup",
      field: "new_setup",
      active: true
    },
    {
      header: "Remote Setup",
      field: "remote_setup",
      active: true
    },
    {
      header: "On Hold",
      field: "on_hold",
      active: true
    },

    {
      header: "Has Recalled Machine",
      field: "has_recalled_machine",
      active: false
    },
    {
      header: "Recall Acknowledged",
      field: "has_acknowledged_recall",
      active: false
    },
    {
      header: "Recall status",
      field: "recall_action_status",
      active: false
    },
    {
      header: "Repap Eligible",
      field: "repap_eligible",
      active: false
    },
    {
      header: "Repap Requested",
      field: "wants_to_repap",
      active: false
    },
    {
      header: "Patient Stage",
      field: "patient_type",
      active: true
    },
    {
      header: "Teams",
      field: "company_team_name",
      active: false
    },
    {
      header: "Compliance Inactivation Reason",
      field: "compliance_inactivation_reason",
    },
  ];
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    visibleColumns,
    resetTableSettings
  } = useTableSettings("patients-table-settings", columns);

  // remove sorting and filters when column is removed
  useEffect(() => {
    const newQuery = cleanQuery(visibleColumns, query);
    if (!isEqual(newQuery, query)) {
      queryDispatcher({ type: "updateQuery", ...newQuery });
    }
  }, [visibleColumns, queryDispatcher]);
  return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  };
};

export default usePatientsTableSettings;
